@alain-ms-search-width: 150px;
@alain-ms-search-height: 26px;

@{alain-ms-prefix}__search {
  position: relative;
  float: right;
  width: @alain-ms-search-width;
  height: @alain-ms-search-height;
  background: rgba(255, 255, 255, 0.12);
  transition: all 0.3s linear;
  &:hover,
  &-active {
    width: @alain-ms-search-width + 38px;
    background: #262c30;
    @{alain-ms-prefix}__search-outline {
      opacity: 1;
    }
  }
  &-ipt {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding-right: 22px;
    padding-left: 12px;
    color: #fff;
    font-size: 12px;
    line-height: @alain-ms-search-height;
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      box-shadow: none;
    }
  }
  &-icon {
    position: absolute;
    top: ((@alain-ms-search-height - 14) / 2);
    right: 0;
    bottom: 0;
    z-index: 3;
    width: 26px;
    color: #fff;
  }
  &-outline {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #00c1de;
    opacity: 0;
    transition: all 0.3s linear;
  }
  &::after {
    position: absolute;
    top: 5px;
    right: @alain-ms-search-height;
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.15);
    content: '';
  }
}
