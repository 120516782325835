@media screen and (max-width: @nz-table-rep-max-width) {
  .ant-table-rep .ant-table-tbody > tr:nth-child(3n) {
    background: unset;
  }

  .@{table-prefix-cls} {
    &-tbody {
      > tr:nth-child(4n-1) {
        &,
        .ant-table-td-left-sticky,
        .ant-table-td-right-sticky {
          background-color: @antd-base-grey !important;
        }
      }
    }
  }
}

@media only screen and (max-width: @screen-sm-max) {
  st {
    width: 100%;
    .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
      margin: 0 !important;
    }
  }

  .ant-table-rep .ant-table table > col {
    width: 100% !important;
    min-width: 100% !important;
  }
}
