@alain-ms-component-page-single-prefix: ~'.ms-page-single';

@{alain-ms-component-page-single-prefix} {
  display: block;
  &__wrap {
    display: flex;
    width: @alain-ms-component-page-single-width;
    margin: 0 auto;
  }
  &__wide {
    width: 100%;
  }
  &__bar {
    display: flex;
    height: @alain-ms-component-page-single-topbar-height;
    color: @alain-ms-component-page-single-topbar-color;
    background: @alain-ms-component-page-single-topbar-bg;
    &-desc {
      display: flex;
      flex: 1;
      align-items: center;
      justify-items: center;
    }
    &-extra {
      display: flex;
      align-items: center;
      justify-items: center;
    }
  }
}
