.account {
  &-container {
    display: block;
    background-color: @brand-grey;
    &__light {
      background-color: #fff;
    }
  }
  &-box {
    position: relative;
    width: 1000px;
    margin: 0 auto;
    padding: 80px 0;
    &__wrap {
      background: #fff;
    }
    @media (max-width: @mobile-max) {
      &,
      > div {
        width: 100% !important;
      }
      > div {
        padding: 0 24px;
      }
    }
  }
}
