@{alain-ms-prefix} {
  display: block;
  height: 100%;
  overflow: hidden;

  &__has {
    &-topbar {
      padding-top: @alain-ms-topbar-height;
      @{alain-ms-prefix}__sidebar {
        top: @alain-ms-topbar-height;
      }
    }
    &-sidebar {
      padding-left: @alain-ms-sidebar-width - 2;
    }
  }

  // 产品页
  &__product {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    overflow: hidden;
    background: @alain-ms-body-bg;
    &-body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      overflow: hidden;
      overflow-y: auto;
      transition: left 0.2s ease;
    }
    // 携带一栏
    &-col-1 {
      @{alain-ms-prefix}__product-body {
        left: @alain-ms-product-col-1-width;
      }
    }
  }

  // 控制台
  &__console {
    padding: @alain-ms-console-padding-vertical @alain-ms-console-padding-horizontal;
    &-full {
      margin: -@alain-ms-console-padding-vertical -@alain-ms-console-padding-horizontal;
    }

    .router-ant();
  }
}

.ms-layout-rtl-mixin(@enabled) when(@enabled=true) {
  [dir='rtl'] {
    @{alain-ms-prefix} {
      &__has {
        &-sidebar {
          padding-right: @alain-ms-sidebar-width - 2;
          padding-left: inherit;
        }
      }

      // 产品页
      &__product {
        // 携带一栏
        &-col-1 {
          @{alain-ms-prefix}__product-body {
            right: @alain-ms-product-col-1-width;
            left: 0;
          }
        }
      }
    }
  }
}
.ms-layout-rtl-mixin(@rtl-enabled);
