// 动画
.special-close-animation-mixin(@enabled) when(@enabled=true) {
  // antd
  .@{btn-prefix-cls},
  .@{input-prefix-cls},
  .@{card-prefix-cls},
  .@{tag-prefix-cls} {
    transition: none;
  }
}

.special-close-animation-mixin(@special-close-animation-enabled);
