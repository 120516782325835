ul.multi-search {
  &.ant-menu-inline {
    border-right: 0 !important;
  }

  li.ant-menu-submenu.ant-menu-submenu-inline {
    div.ant-menu-submenu-title {
      margin: 0 !important;
      padding: 0 !important;
      i.ant-menu-submenu-arrow {
        display: none !important;
      }
    }
  }
}

.ant-table-selection {
  flex-direction: unset !important;
}
