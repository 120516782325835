@{alain-ms-prefix}__markdown {
  overflow: hidden;
  color: #333;
  font-size: 14px;
  line-height: 1.6;
  word-wrap: break-word;

  & > :first-child {
    &,
    h2 {
      margin-top: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-top: 36px;
    margin-bottom: 16px;
    color: #666;
    font-weight: 700;
    line-height: 1.4;
  }
  h1 {
    padding-bottom: 0;
    font-size: 24px;
    line-height: 24px;
  }
  h2 {
    padding-bottom: 0;
    font-size: 20px;
    line-height: 20px;
  }
  h3 {
    font-size: 16px;
    line-height: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    color: #777;
    font-size: 1em;
  }
  a {
    color: @brand-color;
    &:hover {
      text-decoration: underline;
    }
  }
  blockquote,
  dl,
  ol,
  p,
  pre,
  table,
  ul {
    margin-top: 0;
    margin-bottom: 16px;
  }
  hr {
    height: 4px;
    margin: 16px 0;
    padding: 0;
    background-color: #e7e7e7;
    border: 0 none;
  }
  img {
    box-sizing: border-box;
    max-width: 100%;
  }
  ul,
  ol {
    padding-left: 17px;
    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  li {
    > p {
      margin-top: 16px;
    }
  }
  dl {
    padding: 0;
    dt {
      margin-top: 16px;
      padding: 0;
      font-weight: 700;
      font-size: 1em;
      font-style: italic;
    }
    dd {
      margin-bottom: 16px;
      padding: 0 16px;
    }
  }
  blockquote {
    padding: 0 15px;
    color: #777;
    border-left: 4px solid #ddd;
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }
  table {
    display: table;
    width: 100%;
    overflow: auto;
    table-layout: fixed;
    &::-webkit-scrollbar {
      display: none;
    }
    thead {
      tr {
        background-color: #f2f2f2;
      }
    }
    tr {
      background-color: #fff;
    }
    td,
    th {
      padding: 10px 13px;
      border: 1px solid #dfdfdf;
    }
    th {
      color: #333;
      font-weight: 700;
      white-space: inherit;
      border-top: none;
      border-bottom: none;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    td {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    table {
      display: block;
    }
  }
  code {
    margin: 0;
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px;
  }
  pre {
    > code {
      margin: 0;
      padding: 0;
      font-size: 14px;
      white-space: pre;
      word-break: normal;
      background: 0 0;
      border: 0;
    }
  }
}
