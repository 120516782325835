html {
  --alain-ms-body-bg: @alain-ms-body-bg;
  --alain-ms-topbar-height: @alain-ms-topbar-height;
  --alain-ms-topbar-bg: @alain-ms-topbar-bg;
  --alain-ms-sidebar-width: @alain-ms-sidebar-width;
  --alain-ms-sidebar-bg: @alain-ms-sidebar-bg;
  --alain-ms-sidebar-product-bg: @alain-ms-sidebar-product-bg;
  --alain-ms-sidebar-product-width: @alain-ms-sidebar-product-width;
  --alain-ms-sidebar-product-hover-width: @alain-ms-sidebar-product-hover-width;
  --alain-ms-sidebar-product-item-height: @alain-ms-sidebar-product-item-height;
  --alain-ms-sidebar-product-item-icon-font-size: @alain-ms-sidebar-product-item-icon-font-size;
  --alain-ms-sidebar-product-item-name-color: @alain-ms-sidebar-product-item-name-color;
  --alain-ms-sidebar-product-item-name-font-size: @alain-ms-sidebar-product-item-name-font-size;
  --alain-ms-sidebar-product-item-hover-bg: @alain-ms-sidebar-product-item-hover-bg;
  --alain-ms-sidebar-products-bg: @alain-ms-sidebar-products-bg;
  --alain-ms-sidebar-products-width: @alain-ms-sidebar-products-width;
  --alain-ms-sidebar-products-right-width: @alain-ms-sidebar-products-right-width;
  --alain-ms-product-col-1-width: @alain-ms-product-col-1-width;
  --alain-ms-product-col-1-bg: @alain-ms-product-col-1-bg;
  --alain-ms-console-padding-horizontal: @alain-ms-console-padding-horizontal;
  --alain-ms-console-padding-vertical: @alain-ms-console-padding-vertical;
  --brand-bordered-color: @brand-bordered-color;
  --brand-color: @brand-color;
  --brand-hover-color: @brand-hover-color;
  --brand-bg: @brand-bg;
  --brand-grey: @brand-grey;
  --brand-light: @brand-light;
  --brand-head-color: @brand-head-color;
  --special-close-animation-enabled: @special-close-animation-enabled;
  --alain-ms-component-page-nav-collapse-btn-height: @alain-ms-component-page-nav-collapse-btn-height;
  --alain-ms-component-page-nav-collapse-btn-width: @alain-ms-component-page-nav-collapse-btn-width;
  --alain-ms-component-page-nav-collapse-btn-bg: @alain-ms-component-page-nav-collapse-btn-bg;
  --alain-ms-component-page-nav-scene-hover-bg: @alain-ms-component-page-nav-scene-hover-bg;
  --alain-ms-component-page-nav-scene-active-bg: @alain-ms-component-page-nav-scene-active-bg;
  --alain-ms-component-page-nav-scene-title-height: @alain-ms-component-page-nav-scene-title-height;
  --alain-ms-component-page-nav-scene-title-bg: @alain-ms-component-page-nav-scene-title-bg;
  --alain-ms-component-page-nav-scene-item-height: @alain-ms-component-page-nav-scene-item-height;
  --alain-ms-component-page-nav-scene-item-color: @alain-ms-component-page-nav-scene-item-color;
  --alain-ms-component-page-nav-scene-back-icon-fs: @alain-ms-component-page-nav-scene-back-icon-fs;
  --alain-ms-component-help-bg: @alain-ms-component-help-bg;
  --alain-ms-component-page-single-width: @alain-ms-component-page-single-width;
  --alain-ms-component-page-single-topbar-height: @alain-ms-component-page-single-topbar-height;
  --alain-ms-component-page-single-topbar-color: @alain-ms-component-page-single-topbar-color;
  --alain-ms-component-page-single-topbar-bg: @alain-ms-component-page-single-topbar-bg;
  --alain-ms-component-panel-border-color: @alain-ms-component-panel-border-color;
  --alain-ms-component-panel-head-height: @alain-ms-component-panel-head-height;
  --alain-ms-component-panel-head-bg: @alain-ms-component-panel-head-bg;
  --alain-ms-component-panel-cell-padding: @alain-ms-component-panel-cell-padding;
  --fc-list-event-hover-bg-color: @brand-grey !important;
}
