@alain-ms-has-sidebar-prefix: ~'@{alain-ms-prefix}__has-sidebar';

@{alain-ms-prefix} {
  &__sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: @alain-ms-zindex + 10 !important;
    width: @alain-ms-sidebar-width;
    background-color: @alain-ms-sidebar-bg;

    &-wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 999;
      width: @alain-ms-sidebar-product-width;
      overflow: hidden;
      background-color: @alain-ms-sidebar-product-bg;
      transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
      &:hover {
        width: @alain-ms-sidebar-product-hover-width;
        cursor: pointer;
        transition-delay: 0.3s;
      }

      &.cdk-drop-list-dragging {
        width: @alain-ms-sidebar-product-hover-width;
      }
    }

    &-product {
      // 菜单按钮
      & {
        position: relative;
        width: 100%;
        height: @alain-ms-sidebar-product-item-height;
        overflow: hidden;
        color: #fff;
        font-size: 12px;
        font-size: 0;
        line-height: @alain-ms-sidebar-product-item-height;
        white-space: nowrap;
        transition: all 0.2s ease-out 0s;
      }
      &-icon {
        display: inline-block;
        width: @alain-ms-sidebar-product-width;
        color: hsla(0, 0%, 100%, 0.65);
        font-size: @alain-ms-sidebar-product-item-icon-font-size;
        text-align: center;
        vertical-align: middle;
      }
      &-name {
        display: inline-block;
        // 应扣除border边界
        width: @alain-ms-sidebar-product-hover-width - @alain-ms-sidebar-product-width - 2;
        overflow: hidden;
        color: @alain-ms-sidebar-product-item-name-color;
        font-size: @alain-ms-sidebar-product-item-name-font-size;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        &:hover {
          color: @brand-color;
        }
      }
      &-toolbar {
        position: absolute;
        top: 50%;
        display: none;
        width: 50px;
        height: @alain-ms-sidebar-product-item-height;
        margin-left: -50px;
        color: hsla(0, 0%, 100%, 0.65);
        font-size: 12px;
        line-height: @alain-ms-sidebar-product-item-height;
        text-align: center;
        transform: translateY(-50%);
        &-remove,
        &-drag {
          display: inline-block;
          width: 25px;
          height: @alain-ms-sidebar-product-item-height;
          color: hsla(0, 0%, 100%, 0.45);
          font-size: 14px;
          line-height: @alain-ms-sidebar-product-item-height;
          text-align: center;
          &:hover {
            color: hsla(0, 0%, 100%, 0.85);
          }
        }
        &-drag {
          cursor: move;
        }
      }
      &:hover {
        background-color: @alain-ms-sidebar-product-item-hover-bg;

        @{alain-ms-prefix}__sidebar {
          &-product-toolbar {
            display: inline;
            background-color: @alain-ms-sidebar-product-item-hover-bg;
            box-shadow: -5px 0 10px rgba(25, 33, 41, 0.5);
          }
          &-product-icon {
            color: #fff;
          }
        }
      }
      // 拖动状态
      &.cdk-drag-dragging,
      &.cdk-drag-preview {
        width: @alain-ms-sidebar-product-hover-width !important;
      }
      // 所有菜单按钮
      &-all {
        position: relative;
        width: 100%;
        height: 50px;
        padding: 4px 0;
        overflow: hidden;
        line-height: 50px;
        white-space: nowrap;
        border-top: 1px solid hsla(0, 0%, 100%, 0.1);
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
        &-wrap {
          height: @alain-ms-sidebar-product-item-height;
          line-height: @alain-ms-sidebar-product-item-height;
          &:hover {
            background-color: @brand-color;
          }
        }
        &:hover {
          @{alain-ms-prefix}__sidebar-product {
            &-name {
              color: @alain-ms-sidebar-product-item-name-color;
            }
            &-toolbar {
              font-size: 16px;
              background-color: transparent;
              box-shadow: none;
            }
          }
        }
      }
      // 快捷菜单
      &-quick {
        position: relative;
        // 隐藏滚动条
        width: calc(100% + 20px);
        height: calc(100% - 48px);
        margin: 0;
        padding: 0;
        overflow-y: auto;
        list-style: none;
      }
      // 所有产品清单
      &s {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -@alain-ms-sidebar-products-width;
        z-index: 99;
        width: @alain-ms-sidebar-products-width;
        padding: 40px 30px 0 30px;
        overflow: hidden;
        background-color: @alain-ms-sidebar-products-bg;
        box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.2);
        transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }

    // 显示所有产品
    &-showproduct {
      @{alain-ms-prefix}__sidebar {
        &-wrap {
          width: @alain-ms-sidebar-product-hover-width;
        }
        &-products {
          left: @alain-ms-sidebar-product-hover-width;
        }
      }
    }
  }

  // 所有产品
  &__products {
    display: flex;
    height: 100%;

    &-close {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 8px;
      cursor: pointer;
    }
    &-left {
      position: relative;
      flex: 1;
      overflow: hidden;
    }
    &-right {
      width: @alain-ms-sidebar-products-right-width;
      margin-top: 60px;
    }
    // 单元
    &-search {
      padding-right: 48px;
      .@{ant-prefix}-input {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        box-shadow: none;
      }
    }
    &-category {
      &-wrap {
        width: 110%;
        height: 100%;
        margin: 24px 0;
        padding-bottom: 80px;
        overflow-y: auto;
      }
      &-column {
        width: 230px;
        margin-right: 16px;
      }
      & {
        &-title {
          height: 40px;
          padding: 0 8px;
          color: #000;
          font-weight: 600;
          font-size: 14px;
          line-height: 40px;
        }
        &-item {
          position: relative;
          &-link {
            display: block;
            height: 30px;
            padding-right: 30px;
            padding-left: 10px;
            overflow: hidden;
            color: #000;
            font-size: 12px;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            opacity: 0.65;
          }
          &-icon {
            position: absolute;
            top: 50%;
            right: 0;
            display: none;
            padding: 8px;
            color: @brand-color;
            transform: translateY(-50%);
            cursor: pointer;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.06);
            @{alain-ms-prefix}__products-category-item-icon {
              display: block;
            }
          }
          &-active {
            @{alain-ms-prefix}__products-category-item-icon {
              display: block;
            }
          }
        }
      }
    }
    // fix antd components
    .@{ant-prefix}-anchor {
      font-size: 12px;
      &-link-active {
        position: relative;
        z-index: 1;
        margin-left: -2px;
        border-left: 2px solid @brand-color;
        cursor: pointer;
      }
    }
  }
}

.ms-sidebar-rtl-mixin(@enabled) when(@enabled=true) {
  [dir='rtl'] {
    @{alain-ms-prefix} {
      &__sidebar {
        right: 0;
        left: inherit;
        &-product {
          // 所有产品清单
          &s {
            right: -@alain-ms-sidebar-products-width;
            left: 0;
          }
        }
        // 显示所有产品
        &-showproduct {
          @{alain-ms-prefix}__sidebar {
            &-products {
              right: @alain-ms-sidebar-product-hover-width;
              left: 0;
            }
          }
        }
      }
    }
  }
}
.ms-sidebar-rtl-mixin(@rtl-enabled);
