@ay-footer-prefix: ~'.ay-footer';
@ay-footer-bg: #373d41;
@ay-footer-width: 1200px;
@ay-footer-padding: 24px;
@ay-footer-color: #73777a;
@ay-footer-secondary-color: #9b9ea0;
@ay-footer-line-color: #4b5054;
@ay-footer-light-color: #d7d8d9;

@ay-footer-mobile-padding: 16px;

@{ay-footer-prefix} {
  display: block;
  background-color: @ay-footer-bg;

  &__wrap {
    max-width: @ay-footer-width;
    margin: 0 auto;
  }

  &__pc {
    a {
      color: @ay-footer-color;
      &:hover {
        color: @brand-color;
      }
    }
  }

  &__know {
    padding-top: @ay-footer-padding;
    @{ay-footer-prefix}__wrap {
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: @ay-footer-padding;
      border-bottom: 1px solid @ay-footer-line-color;
    }
    &-links {
      padding: 0;
      list-style: none;
      > li {
        line-height: 30px;
        a {
          color: @ay-footer-secondary-color;
        }
      }
    }
    &-imgs {
      img {
        display: none;
      }
      .active {
        img {
          display: block;
        }
      }
    }
    &-tel {
      display: flex;
      width: 280px;
      color: @ay-footer-light-color;
    }
    &-menus {
      display: flex;
      flex: 1;
      list-style: none;
      > li {
        width: 22%;
        &:last-child {
          width: 12%;
        }
      }
      h3 {
        color: @ay-footer-light-color;
      }
      img {
        max-width: 80px;
        margin-bottom: 8px;
      }
    }
  }

  &__hot {
    padding-top: @ay-footer-padding;
    @{ay-footer-prefix}__wrap {
      padding-bottom: @ay-footer-padding;
      border-bottom: 1px solid @ay-footer-line-color;
    }
    &-item {
      display: flex;
      align-items: center;
    }
    &-title {
      width: 80px;
      margin-top: 8px;
      color: @ay-footer-secondary-color;
    }
    &-links {
      a {
        display: inline-block;
        width: 120px;
        margin-top: 8px;
      }
    }
  }

  &__cr {
    display: block;
    padding: @ay-footer-padding;
    color: @ay-footer-color;
    font-size: 14px;
    background-color: @ay-footer-bg;

    &-nav,
    &-links {
      display: flex;
      align-items: center;
      a {
        margin-right: 24px;
        color: @ay-footer-color;
        &:hover {
          color: @brand-color;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &-nav {
      padding-top: 24px;

      a {
        color: @ay-footer-secondary-color;
        font-size: 16px;
      }
    }

    &-links {
      padding-top: 32px;
    }
  }
}

@{ay-footer-prefix}__m {
  padding: @ay-footer-mobile-padding;
  color: @ay-footer-light-color;
  a {
    margin-right: 16px;
    color: @ay-footer-light-color;
    line-height: 30px;
  }
  &-line {
    padding-bottom: @ay-footer-mobile-padding;
    border-bottom: 1px solid @ay-footer-line-color;
  }
  &-hot {
    &-title {
      display: block;
      margin-bottom: 8px;
      color: @ay-footer-color;
    }
  }
  &-tel {
    > a {
      display: block;
      height: 36px;
      margin-right: 0;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      border: 1px solid @ay-footer-light-color;
      border-radius: 2px;
    }
  }
}

@media (max-width: @mobile-max) {
  @{ay-footer-prefix}__cr {
    &-nav,
    &-links {
      display: none;
    }
  }
}
