@alain-ms-component-panel-prefix: ~'.ms-panel';

@{alain-ms-component-panel-prefix} {
  position: relative;
  display: block;
  margin-bottom: 16px;
  border-right: 1px solid @alain-ms-component-panel-border-color;
  &__hd {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @alain-ms-component-panel-head-height;
    padding: 0 16px;
    background: @alain-ms-component-panel-head-bg;
    border: 1px solid @alain-ms-component-panel-border-color;
    border-right: none;
    &::after {
      position: absolute;
      top: -1px;
      bottom: 0;
      left: -1px;
      display: block;
      width: 3px;
      background: #778;
      content: ' ';
    }
    &-title {
      font-size: 14px;
    }
  }
  &__bd {
    // border: 1px solid @alain-ms-component-panel-border-color;
  }
  @{sv-prefix} {
    &__container > .@{ant-prefix}-row {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0 !important;
      margin-bottom: 0;
      margin-left: 0 !important;
      overflow: initial;
    }
    &__item {
      padding: @alain-ms-component-panel-cell-padding !important;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
      }
      &::before {
        right: 0;
        bottom: 0;
        left: 0;
        height: 0;
        border-top: 1px solid @alain-ms-component-panel-border-color;
      }
      &::after {
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        border-left: 1px solid @alain-ms-component-panel-border-color;
      }
    }
    &__label,
    &__detail {
      padding-bottom: 0;
    }
  }
}
