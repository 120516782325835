@alain-ms-body-prefix: ~'@{alain-ms-prefix}__body';

@{alain-ms-body-prefix} {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @alain-ms-zindex;
  display: block;
  width: 100%;
  width: auto;
}

@{alain-ms-has-topbar-prefix} @{alain-ms-body-prefix} {
  top: @alain-ms-topbar-height;
}

@{alain-ms-has-sidebar-prefix} @{alain-ms-body-prefix} {
  left: @alain-ms-sidebar-width;
}

.ms-body-rtl-mixin(@enabled) when(@enabled=true) {
  [dir='rtl'] {
    @{alain-ms-has-sidebar-prefix} @{alain-ms-body-prefix} {
      right: @alain-ms-sidebar-width;
      left: 0;
    }
  }
}
.ms-body-rtl-mixin(@rtl-enabled);
