@chat-avatar-width: 32px;

.chat {
  &__container {
    display: flex;
    overflow: hidden;
    background: #fff;
    border: 1px solid rgba(24, 28, 33, 0.06);
  }
  &__scroll {
    &-container {
      position: relative;
      width: 100%;
    }
  }
  &__user {
    &-avatar {
      height: @chat-avatar-width;
      border-radius: 50%;
    }
  }
  &__message {
    &-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 24px;
    }

    display: flex;
    flex-shrink: 0;
    margin-bottom: 8px;
    &-left {
      margin-right: @chat-avatar-width + 8px;
      .chat__message-avatar {
        margin-right: 8px;
      }
    }
    &-right {
      flex-direction: row-reverse;
      margin-left: @chat-avatar-width + 8px;
      .chat__message-avatar {
        margin-left: 8px;
      }
    }
    &-send {
      text-align: center;
    }
    &-avatar {
      width: @chat-avatar-width;
      text-align: center;
    }
    &-text {
      width: 100%;
      color: @grey-6;
      font-size: 12px;
      text-align: center;
    }
    &-time {
      padding-top: 2px;
      color: @grey-6;
      font-size: 12px;
      white-space: nowrap;
    }
    &-msg {
      padding: 8px;
      background: @grey-3;
      border-radius: 4px;
      &--name {
        display: block;
        margin-bottom: 2px;
      }
    }
  }
}

[dir='rtl'] {
  .chat {
    &__message {
      &-left {
        margin-right: 0;
        margin-left: @chat-avatar-width + 8px;
        .chat__message-avatar {
          margin-right: 0;
          margin-left: 8px;
        }
      }
      &-right {
        margin-right: @chat-avatar-width + 8px;
        margin-left: 0;
        .chat__message-avatar {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }
}
