.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .ant-modal {
    top: 8px;
    flex: 1 0 auto;
    padding: 0;
  }

  // .ant-modal-content {
  //   overflow-y: hidden;
  // }

  .ant-modal-body {
    padding: 0 !important;

    .wrap {
      position: relative;
      max-height: 70vh !important;
      padding: 24px;
      overflow: auto;
    }

    .modal-footer {
      margin: 0;
      padding: 10px 24px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .vertical-center-modal.modal-lg .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
}

.delete-modal {
  .ant-modal-body {
    padding: 24px !important;
  }
  .ant-modal-confirm-btns {
    text-align: right;
  }
}

.sf-modal {
  .ant-modal-body {
    padding: 24px !important;
  }
}
