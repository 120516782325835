.@{table-prefix-cls} {
  border: @table-border;
  &-empty {
    .@{table-prefix-cls}-placeholder {
      line-height: 80px;
    }
  }
}

.table-even-bg-mixin(@enabled) when(@enabled=true) {
  .@{table-prefix-cls} {
    &-tbody {
      > tr:nth-child(4n-1) {
        &,
        .ant-table-td-left-sticky,
        .ant-table-td-right-sticky {
          background-color: @antd-base-grey;
        }
      }
    }
  }
}

.table-even-bg-mixin(@table-even-bg-enabled);
