.@{tab-prefix-cls} {
  &&-card > &-bar &-tab {
    margin-right: -1px;
    transition: none;
    &-active {
      font-weight: normal;
      &::before {
        position: absolute;
        top: -1px;
        right: -1px;
        left: -1px;
        display: block;
        height: 2px;
        background-color: @brand-color;
        // animation: tabs-tab-move 0.4s;
        content: '';
      }
    }
  }
}

@keyframes tabs-tab-move {
  from {
    right: 50%;
    left: 50%;
  }
  to {
    right: -1px;
    left: -1px;
  }
}
