@{alain-ms-prefix}__user {
  &-hd {
    padding: 10px 16px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  &-bd {
    padding: 8px 0;
    &-item {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 16px;
      line-height: 32px;
      .anticon {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  &-fd {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
}

.ms-user-rtl-mixin(@enabled) when(@enabled=true) {
  [dir='rtl'] {
    @{alain-ms-prefix}__user {
      &-bd {
        &-item {
          .anticon {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
.ms-user-rtl-mixin(@rtl-enabled);
