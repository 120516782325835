@account-topbar-prefix: ~'.account-topbar';
@account-topbar-height: 50px;

@{account-topbar-prefix} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @account-topbar-height;
  padding: 0 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &__logo {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-family: Tahoma, Geneva, sans-serif;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__light {
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: @brand-color;
      }
    }
  }

  &__dark {
    color: #373d41;
    a {
      color: #373d41;
      &:hover {
        color: @brand-color;
      }
    }
  }
}
