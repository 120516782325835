@alain-ms-component-page-nav-prefix: ~'.ms-page-nav';

@{alain-ms-component-page-nav-prefix} {
  &__body {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: @alain-ms-zindex + 1;
    width: 0;
    overflow: hidden;
    background-color: @alain-ms-product-col-1-bg;
    transition: width 0.2s ease;
  }
  &__control {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: @alain-ms-zindex + 2;
    width: @alain-ms-component-page-nav-collapse-btn-width;
    height: @alain-ms-component-page-nav-collapse-btn-height;
    transition: width 0.2s ease;
    &:hover {
      @{alain-ms-component-page-nav-prefix}__control {
        &-bg {
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 20px solid @brand-grey;
        }
        &-btn {
          left: 0;
        }
      }
    }
    &-wrap {
      position: relative;
      top: -50%;
      overflow: hidden;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: @alain-ms-component-page-nav-collapse-btn-height;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 13px solid @alain-ms-component-page-nav-collapse-btn-bg;
      transition: all 0.1s ease, 0.1s ease;
    }
    &-btn {
      position: relative;
      left: -7px;
      height: @alain-ms-component-page-nav-collapse-btn-height;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s ease, 0.1s ease;
      .anticon {
        color: #546478;
        font-size: 15px;
        line-height: @alain-ms-component-page-nav-collapse-btn-height;
        vertical-align: text-top;
        transform: rotate(180deg);
      }
    }
  }
  &__stage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: @alain-ms-product-col-1-width;
    overflow: hidden;
  }
  &__scene {
    position: absolute;
    top: 0;
    bottom: 0;
    width: @alain-ms-product-col-1-width;
    transition: position, 0.2s, linear;
    &-main {
      left: 0;
      @{alain-ms-component-page-nav-prefix} {
        &__title {
          font-weight: bold;
          text-indent: 20px;
        }
        &__back {
          font-size: @alain-ms-component-page-nav-scene-back-icon-fs;
          text-align: center;
          text-indent: 0;
          cursor: pointer;
        }
      }
    }
  }
  &__title {
    width: @alain-ms-product-col-1-width;
    height: @alain-ms-component-page-nav-scene-title-height;
    overflow: hidden;
    line-height: @alain-ms-component-page-nav-scene-title-height;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: @alain-ms-component-page-nav-scene-title-bg;
    border-bottom: 1px solid @brand-bordered-color;
  }
  &__list {
    position: absolute;
    top: @alain-ms-component-page-nav-scene-title-height;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;

    li ul {
      text-indent: 8px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    width: @alain-ms-product-col-1-width;
    height: @alain-ms-component-page-nav-scene-item-height;
    color: @alain-ms-component-page-nav-scene-item-color;
    line-height: @alain-ms-component-page-nav-scene-item-height;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: @alain-ms-component-page-nav-scene-hover-bg;
    }
    &-active {
      background-color: @alain-ms-component-page-nav-scene-active-bg;
    }
    &-icon {
      width: 30px;
      height: 40px;
      color: #333;
      font-size: 16px;
      text-align: center;
    }
    &-tit {
      flex: 1;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-badge {
      display: inline-block;
      height: 24px;
      margin-right: 8px;
      padding: 0 8px;
      color: #fff;
      line-height: 24px;
      text-align: center;
      text-indent: 0;
      background: @brand-color;
      border-radius: 2px;
    }
  }
}

@{alain-ms-prefix}__product-col {
  // 一栏
  &-1 {
    @{alain-ms-component-page-nav-prefix} {
      &__body {
        width: @alain-ms-product-col-1-width;
      }
      &__control {
        left: @alain-ms-product-col-1-width - @alain-ms-component-page-nav-collapse-btn-width;
        &:hover {
          @{alain-ms-component-page-nav-prefix}__control {
            &-bg {
              border-top: 8px solid transparent;
              border-right: 20px solid @brand-grey;
              border-bottom: 8px solid transparent;
              border-left: none;
            }
            &-btn {
              right: 0;
              left: auto;
            }
          }
        }
        &-bg {
          right: 0;
          left: auto;
          border-top: 9px solid transparent;
          border-right: 13px solid @brand-grey;
          border-bottom: 9px solid transparent;
          border-left: none;
        }
        &-btn {
          right: -7px;
          left: auto;
          .anticon {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
