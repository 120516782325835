.@{dialog-prefix-cls},
.modal {
  &-header,
  &-footer {
    background: @brand-grey;
  }
}

.@{dialog-prefix-cls} {
  &-mask {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &-content {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  }
}
