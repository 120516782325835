svg {
  g.nodes > .node {
    foreignobject > div {
      span:first-child {
        font-weight: bold;
      }
      span:last-child {
        font-size: 1em;
      }
    }
  }
}

// .gantt-schedule-timeline-calendar

.gantt-schedule-timeline-calendar {
  background: @alain-ms-product-col-1-bg;

  &__horizontal-scroll {
    height: 15px;
  }

  &__chart-calendar,
  &__list-column-header {
    background: @brand-grey;
  }

  &__list-column-header-resizer-dots-dot {
    background: @brand-bordered-color;
  }

  &__list-toggle {
    padding: 2px !important;
  }

  &__list-column-row {
    border-right: 1px solid @brand-bordered-color;
    border-bottom: 1px solid @brand-bordered-color;
  }

  &__chart-timeline-grid-row-block {
    border-right: 1px solid @brand-bordered-color;
    border-bottom: 1px solid @brand-bordered-color;
  }

  &__chart-calendar-date-content--day {
    border-right: 1px solid @brand-bordered-color;
  }
}
