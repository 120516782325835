@font-face {
  font-family: 'biz360-iconfont'; /* Project id 1822540 */
  src: url('iconfont.woff2?t=1631069475159') format('woff2'), url('iconfont.woff?t=1631069475159') format('woff'),
    url('iconfont.ttf?t=1631069475159') format('truetype');
}

.iconfont {
  font-size: 16px;
  font-family: 'biz360-iconfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-film-fill::before {
  content: '\ea3d';
}

.icon-medicinebox::before {
  content: '\e7cd';
}

.icon-video::before {
  content: '\e7da';
}

.icon-key::before {
  content: '\e7e5';
}

.icon-application-fill::before {
  content: '\e671';
}

.icon-lock-access::before {
  content: '\e816';
}

.icon-resource_setting::before {
  content: '\e600';
}

.icon-IdentitycardOutline::before {
  content: '\e6a3';
}

.icon-select::before {
  content: '\e7d4';
}

.icon-square-full::before {
  content: '\e6cd';
}

.icon-fullscreen-exit::before {
  content: '\e7f0';
}

.icon-upload::before {
  content: '\e7f1';
}

.icon-download::before {
  content: '\e7f2';
}

.icon-exclaimination::before {
  content: '\e7f7';
}

.icon-infomation::before {
  content: '\e7f8';
}

.icon-check::before {
  content: '\e7fc';
}

.icon-ellipsis::before {
  content: '\e7fd';
}

.icon-close::before {
  content: '\e7fe';
}

.icon-enter::before {
  content: '\e7ff';
}

.icon-minus::before {
  content: '\e800';
}

.icon-question::before {
  content: '\e801';
}

.icon-rollback::before {
  content: '\e802';
}

.icon-check-circle-fill::before {
  content: '\e844';
}

.icon-left-circle-fill::before {
  content: '\e845';
}

.icon-down-circle-fill::before {
  content: '\e846';
}

.icon-minus-circle-fill::before {
  content: '\e847';
}

.icon-close-circle-fill::before {
  content: '\e848';
}

.icon-info-circle-fill::before {
  content: '\e849';
}

.icon-up-circle-fill::before {
  content: '\e84a';
}

.icon-right-circle-fill::before {
  content: '\e84b';
}

.icon-plus-circle-fill::before {
  content: '\e84c';
}

.icon-question-circle-fill::before {
  content: '\e84d';
}

.icon-check-square-fill::before {
  content: '\e84e';
}

.icon-down-square-fill::before {
  content: '\e84f';
}

.icon-minus-square-fill::before {
  content: '\e850';
}

.icon-close-square-fill::before {
  content: '\e851';
}

.icon-left-square-fill::before {
  content: '\e852';
}

.icon-play-square-fill::before {
  content: '\e853';
}

.icon-up-square-fill::before {
  content: '\e854';
}

.icon-right-square-fill::before {
  content: '\e855';
}

.icon-plus-square-fill::before {
  content: '\e856';
}

.icon-filter-fill::before {
  content: '\e866';
}

.icon-zoomout::before {
  content: '\e897';
}

.icon-apartment::before {
  content: '\e898';
}

.icon-zoomin::before {
  content: '\e899';
}

.icon-caret-down::before {
  content: '\e8ec';
}

.icon-caret-up::before {
  content: '\e8ed';
}

.icon-caret-right::before {
  content: '\e8ee';
}

.icon-caret-left::before {
  content: '\e8ef';
}

.icon-search::before {
  content: '\e8f0';
}

.icon-plus::before {
  content: '\e8fe';
}

.icon-clear::before {
  content: '\e900';
}

.icon-compress::before {
  content: '\e914';
}

.icon-expend::before {
  content: '\e915';
}

.icon-folder-view::before {
  content: '\e916';
}

.icon-send::before {
  content: '\e917';
}

.icon-Report::before {
  content: '\e918';
}

.icon-View::before {
  content: '\e919';
}

.icon-check-circle::before {
  content: '\e77d';
}

.icon-close-circle::before {
  content: '\e77e';
}

.icon-info-circle::before {
  content: '\e77f';
}

.icon-left-circle::before {
  content: '\e780';
}

.icon-down-circle::before {
  content: '\e781';
}

.icon-minus-circle::before {
  content: '\e782';
}

.icon-plus-circle::before {
  content: '\e783';
}

.icon-question-circle::before {
  content: '\e784';
}

.icon-right-circle::before {
  content: '\e785';
}

.icon-warning-circle::before {
  content: '\e786';
}

.icon-sync::before {
  content: '\e787';
}

.icon-undo::before {
  content: '\e788';
}

.icon-redo::before {
  content: '\e789';
}

.icon-reload::before {
  content: '\e78a';
}

.icon-reloadtime::before {
  content: '\e78b';
}

.icon-setting::before {
  content: '\e78e';
}

.icon-eye::before {
  content: '\e78f';
}

.icon-export::before {
  content: '\e791';
}

.icon-save::before {
  content: '\e792';
}

.icon-Import::before {
  content: '\e793';
}

.icon-close-square::before {
  content: '\e794';
}

.icon-down-square::before {
  content: '\e795';
}

.icon-left-square::before {
  content: '\e796';
}

.icon-minus-square::before {
  content: '\e797';
}

.icon-plus-square::before {
  content: '\e798';
}

.icon-right-square::before {
  content: '\e799';
}

.icon-up-square::before {
  content: '\e79a';
}

.icon-check-square::before {
  content: '\e7a8';
}

.icon-alert::before {
  content: '\e7c3';
}

.icon-delete::before {
  content: '\e7c4';
}

.icon-filter::before {
  content: '\e7c7';
}

.icon-printer::before {
  content: '\e7d8';
}

.icon-image::before {
  content: '\e7de';
}

.icon-mail::before {
  content: '\e7df';
}

.icon-attachment::before {
  content: '\e7e1';
}

.icon-edit::before {
  content: '\e7e2';
}

.icon-link::before {
  content: '\e7e3';
}

.icon-phone::before {
  content: '\e7e4';
}

.icon-share::before {
  content: '\e7e7';
}

.icon-right::before {
  content: '\e7eb';
}

.icon-left::before {
  content: '\e7ec';
}

.icon-up::before {
  content: '\e7ed';
}

.icon-down::before {
  content: '\e7ee';
}

.icon-fullscreen::before {
  content: '\e7ef';
}

.icon-preview::before {
  content: '\e65f';
}

.icon-caret::before {
  content: '\e8c0';
}

.icon-file-status-view::before {
  content: '\ea5e';
}

.icon-kaiguan-copy::before {
  content: '\e601';
}

.icon-custom::before {
  content: '\e602';
}

.icon-custom-block::before {
  content: '\e64a';
}

.icon-BasicFunction::before {
  content: '\e966';
}

.icon-dashboards-fill::before {
  content: '\eba5';
}

.icon-communications-fill::before {
  content: '\e67b';
}

.icon-hr-fill::before {
  content: '\e620';
}

.icon-organisation::before {
  content: '\e665';
}

.icon-supports-fill::before {
  content: '\ea3c';
}

.icon-claims::before {
  content: '\e8d3';
}
