@{alain-ms-prefix}__region {
  display: block;
  margin-left: 24px;

  .icon {
    display: inline-block;
    width: 20px;
    height: 14px;
    margin-right: 4px;
    vertical-align: middle;
    background-image: url('//img.alicdn.com/tfs/TB1dMl1dkvoK1RjSZFwXXciCFXa-50-274.png');

    &.icon-ae {
      background-position: 0 0;
    }

    &.icon-ae.disabled {
      background-position: -30px 0;
    }

    &.icon-au {
      background-position: 0 -20px;
    }

    &.icon-au.disabled {
      background-position: -30px -20px;
    }

    &.icon-cn {
      background-position: 0 -40px;
    }

    &.icon-cn.disabled {
      background-position: -30px -40px;
    }

    &.icon-de {
      background-position: 0 -60px;
    }

    &.icon-de.disabled {
      background-position: -30px -60px;
    }

    &.icon-hk {
      background-position: 0 -80px;
    }

    &.icon-hk.disabled {
      background-position: -30px -80px;
    }

    &.icon-in {
      background-position: 0 -100px;
    }

    &.icon-in.disabled {
      background-position: -30px -100px;
    }

    &.icon-jp {
      background-position: 0 -120px;
    }

    &.icon-jp.disabled {
      background-position: -30px -120px;
    }

    &.icon-my {
      background-position: 0 -140px;
    }

    &.icon-my.disabled {
      background-position: -30px -140px;
    }

    &.icon-ru {
      background-position: 0 -160px;
    }

    &.icon-ru.disabled {
      background-position: -30px -160px;
    }

    &.icon-sg {
      background-position: 0 -180px;
    }

    &.icon-sg.disabled {
      background-position: -30px -180px;
    }

    &.icon-us {
      background-position: 0 -200px;
    }

    &.icon-us.disabled {
      background-position: -30px -200px;
    }

    &.icon-id {
      background-position: 0 -220px;
    }

    &.icon-id.disabled {
      background-position: -30px -220px;
    }

    &.icon-kr {
      background-position: 0 -240px;
    }

    &.icon-kr.disabled {
      background-position: -30px -240px;
    }

    &.icon-gb {
      background-position: 0 -260px;
    }

    &.icon-gb.disabled {
      background-position: -30px -260px;
    }
  }
  &--wrap {
    width: 330px;
  }
  &--list {
    float: left;
    max-width: 150px;
    margin-bottom: 8px;
    margin-left: 16px;

    dd {
      position: relative;
      margin: 10px 0 0 0;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
