@alain-ms-component-help-prefix: ~'.ms-help';

@{alain-ms-component-help-prefix} {
  position: fixed;
  right: 8px;
  bottom: 20%;
  background: @alain-ms-component-help-bg;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
  &__wrap {
    padding: 8px;
    text-align: center;
    cursor: pointer;
  }
  &__text {
    display: inline-block;
    width: 14px;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
  }
}
