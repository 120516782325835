html {
  --antd-base-grey: @antd-base-grey;
  --link-color: @link-color;
  --link-hover-color: @link-hover-color;
  --primary-color: @primary-color;
  --border-radius-base: @border-radius-base;
  --border-color-base: @border-color-base;
  --border-color-split: @border-color-split;
  --item-active-bg: @item-active-bg;
  --item-hover-bg: @item-hover-bg;
  --layout-body-background: @layout-body-background;
  --btn-border-radius-base: @btn-border-radius-base;
  --btn-border-radius-sm: @btn-border-radius-sm;
  --btn-primary-bg: @btn-primary-bg;
  --table-header-bg: @table-header-bg;
  --table-row-hover-bg: @table-row-hover-bg;
  --table-padding-horizontal: @table-padding-horizontal;
  --table-even-bg-enabled: @table-even-bg-enabled;
  --sv-label-color: @sv-label-color;
  --setting-drawer-enabled: @setting-drawer-enabled;
}
