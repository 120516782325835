// Panel Override
.ms-panel {
  &__hd {
    &-title {
      font-weight: 500;
    }
  }
}

account-login {
  min-height: 100% !important;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}
.ms-page-bar__title-main {
  // font-weight: 600;
  font-size: 16px;

  .ellipsis {
    &__line-clamp {
      padding-bottom: 2px !important;
    }
  }
}

// .alain-ms__product-body {
// background: #f5f5f6;
// }

.ngx-org {
  &-image {
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    padding: 0.25em;
    background-color: white;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  &-name {
    // font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 500;
  }

  &-title {
    // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 12px;
  }
}

.ngx-org {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid darkgrey;
  }

  &-box {
    padding: 0.5em 1em;
    border-radius: 0.2em;
    box-shadow: 0.05em 0.05em 0.2em 0.05em #0000002b;
  }

  &-connector {
    &-horizontal {
      width: 1em;
    }

    &-vertical {
      height: 1em;
    }
  }
}

.sidebar-footer {
  position: absolute;
  bottom: 10px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  color: #ffffff73;
  font-size: 0.7rem;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;

  > span {
    padding: 10px;
  }
}

ul.multi-search {
  &.ant-menu-inline {
    border-right: 0 !important;
  }

  li.ant-menu-submenu.ant-menu-submenu-inline {
    div.ant-menu-submenu-title {
      margin: 0 !important;
      padding: 0 !important;
      i.ant-menu-submenu-arrow {
        display: none !important;
      }
    }
  }
}
