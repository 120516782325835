// 关闭iPad自动响应式
@nz-table-rep-max-width: 767px;

@{st-prefix} {
  a {
    color: @brand-color;
    &:hover {
      color: @brand-hover-color;
    }
  }
}
