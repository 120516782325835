@{alain-ms-prefix}__notice {
  &-hd {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 0 16px;
    line-height: 48px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  &-item {
    display: block;
    min-width: 120px;
    padding: 10px 15px;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    transition: all 0.15s;
    &--title,
    &--time {
      height: 18px;
      line-height: 18px;
    }
    &--title {
      min-width: 200px;
      max-width: 300px;
      overflow: hidden;
      color: hsla(0, 0%, 100%, 0.85);
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: @brand-color;
      }
    }
    &--time {
      color: hsla(0, 0%, 100%, 0.85);
      opacity: 0.65;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &-fd {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
}
